<script setup lang="ts">
const country = useState('country')

onMounted(async () => {
    await callOnce(async () => {
        const response = await fetch('https://api.ipregistry.co/?key=c6yjeqjieeuzoswy')
        const payload = await response.json()
        country.value = payload.location.country.code
    })
})

</script>

<template>
	<Head>
		<title>Neuronoir</title>
	</Head>
  <div>
      <NuxtLayout></NuxtLayout>
  </div>
</template>
